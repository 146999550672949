import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-639e3e3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content position-relative" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "item-body mb-2"
}
const _hoisted_4 = { class: "negative-margin-row mb-3 row m-0" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "bg-info text-white p-2 mb-2 rounded" }
const _hoisted_8 = { class: "row overflow-x-hidden" }
const _hoisted_9 = { class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 center-btn" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 text-end center-btn" }
const _hoisted_12 = { class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 center-btn" }
const _hoisted_13 = { class: "col-lg-6 col-md-6 col-sm-6 col-xs-12 text-end center-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinnerWithBackground = _resolveComponent("LoadingSpinnerWithBackground")!
  const _component_PopupBox = _resolveComponent("PopupBox")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_PaymentDetailsCard = _resolveComponent("PaymentDetailsCard")!
  const _component_SingleChoiceQuestionItem = _resolveComponent("SingleChoiceQuestionItem")!
  const _component_DirectDebitTemplate = _resolveComponent("DirectDebitTemplate")!
  const _component_OnlineBanking = _resolveComponent("OnlineBanking")!
  const _component_DirectDebitForm = _resolveComponent("DirectDebitForm")!
  const _component_DebitPaymentConfirmation = _resolveComponent("DebitPaymentConfirmation")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinnerWithBackground, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isConfirmationPage && _ctx.policyInfo.policy != undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PopupBox, {
            ref: "popupBox",
            title: 'Incorrect details',
            content: 'There was an error with some of the details that you provided. Please review and try again.  Alternatively you can select the internet banking option, or contact Booster to set up a direct debit. ',
            okay: 'Got it',
            "screen-width": _ctx.screenWidth
          }, null, 8, ["content", "screen-width"]),
          _createElementVNode("div", {
            class: _normalizeClass(["item-secondary-title", { 'mb-3': !(_ctx.existingPremiumMonthly > 0) }])
          }, " Payment ", 2),
          (_ctx.existingPremiumMonthly > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " To update your payment method "))
            : _createCommentVNode("", true),
          _createVNode(_component_SelectInput, {
            value: _ctx.payment.frequency,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payment.frequency) = $event)),
            title: 'Payments frequency',
            options: _ctx.frequencies,
            class: "mb-3",
            "validation-error": _ctx.paymentFrequencyError
          }, null, 8, ["value", "options", "validation-error"]),
          (_ctx.policyInfo.policy.policyType == '0')
            ? (_openBlock(), _createBlock(_component_DatePicker, {
                key: 1,
                value: _ctx.policyInfo.policy.nextPremiumDate,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.policyInfo.policy.nextPremiumDate) = $event)),
                title: 'Ongoing premium payments date',
                "validation-error": _ctx.nextPaymentError,
                "is-disabled": _ctx.payment.frequency == null || _ctx.existingPremiumMonthly > 0,
                "min-value": _ctx.getNextDayDate(),
                "max-value": _ctx.ongoingMaxDate,
                class: _normalizeClass(["col-auto px-0 mb-4", { 'me-2': !_ctx.isMobileScreen }]),
                "is-mobile-screen": _ctx.isMobileScreen
              }, null, 8, ["value", "validation-error", "is-disabled", "min-value", "max-value", "class", "is-mobile-screen"]))
            : _createCommentVNode("", true),
          (_ctx.policyInfo.policy.policyType == '0'
        && _ctx.showFirstPremiumPicker)
            ? (_openBlock(), _createBlock(_component_DatePicker, {
                key: 2,
                value: _ctx.policyInfo.policy.firstPremiumDate,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.policyInfo.policy.firstPremiumDate) = $event)),
                title: 'First premium payments date',
                description: 'Should be within 14 days',
                "validation-error": _ctx.firstPaymentError,
                "min-value": _ctx.getNextDayDate(),
                "max-value": _ctx.firstMaxDate,
                class: _normalizeClass(["col-auto px-0 mb-4", { 'me-2': !_ctx.isMobileScreen }]),
                "is-mobile-screen": _ctx.isMobileScreen
              }, null, 8, ["value", "validation-error", "min-value", "max-value", "class", "is-mobile-screen"]))
            : _createCommentVNode("", true),
          (_ctx.shouldShowPaymentDetailsCard)
            ? (_openBlock(), _createBlock(_component_PaymentDetailsCard, {
                key: 3,
                "existing-premium": _ctx.existingPremiumMonthly,
                "payment-amount": _ctx.premiumAmount,
                "pro-rata": _ctx.proRata,
                "policy-type": _ctx.policyInfo.policy.policyType,
                "first-premium-date": _ctx.policyInfo.policy.firstPremiumDate,
                "next-premium-date": _ctx.policyInfo.policy.nextPremiumDate,
                "benefit-renewal-date": _ctx.policyInfo.policy.policyBenefits[0]?.policyBenefitPremiums[0]?.benefitRenewalDate,
                frequency: _ctx.payment.frequency,
                class: "mb-4"
              }, null, 8, ["existing-premium", "payment-amount", "pro-rata", "policy-type", "first-premium-date", "next-premium-date", "benefit-renewal-date", "frequency"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SingleChoiceQuestionItem, {
              value: _ctx.payment.method,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payment.method) = $event)),
              title: 'Select a payment method',
              options: _ctx.paymentMethods,
              "validation-error": _ctx.paymentMethodError
            }, null, 8, ["value", "options", "validation-error"])
          ]),
          (_ctx.payment.method == 'Direct debit')
            ? (_openBlock(), _createBlock(_component_DirectDebitTemplate, {
                key: 4,
                ref: "directDebitTemplateComponent",
                directDebit: _ctx.payment.directDebit,
                "onUpdate:directDebit": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payment.directDebit) = $event)),
                policy: _ctx.policyInfo.policy,
                email: _ctx.policyInfo.insuredPerson.email,
                "full-name": _ctx.getFullName(),
                errors: _ctx.errors,
                "onUpdate:consentQuestions": _cache[5] || (_cache[5] = ($event: any) => (_ctx.setConsentAnswers($event)))
              }, null, 8, ["directDebit", "policy", "email", "full-name", "errors"]))
            : (_ctx.payment.method == 'Online banking')
              ? (_openBlock(), _createBlock(_component_OnlineBanking, { key: 5 }))
              : (_ctx.payment.method == 'Direct debit form')
                ? (_openBlock(), _createBlock(_component_DirectDebitForm, { key: 6 }))
                : _createCommentVNode("", true)
        ]))
      : (_ctx.isConfirmationPage && _ctx.policyInfo.policy != undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_DebitPaymentConfirmation, {
              policy: _ctx.policyInfo.policy,
              payment: _ctx.payment,
              "existing-premiums": _ctx.existingPolicyBenefitPremiums,
              "validation-error": _ctx.errors['debitPaymentConfirmation'],
              onAgreeChange: _cache[6] || (_cache[6] = (agree) => (_ctx.doesAgree = agree))
            }, null, 8, ["policy", "payment", "existing-premiums", "validation-error"])
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("hr", {
      class: _normalizeClass(["hr medium-bottom-spacing", { 'd-none': _ctx.isMobileScreen }])
    }, null, 2),
    (_ctx.errorMsg && _ctx.errorMsg.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.isConfirmationPage)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                class: _normalizeClass(["btn btn-booster-secondary float-start", { 'd-none': _ctx.isMobileScreen }]),
                disabled: _ctx.loading,
                onClick: _cache[7] || (_cache[7] = () => { _ctx.isConfirmationPage = false; })
              }, " Back ", 10, _hoisted_10)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-booster-primary float-end commence-cover-width",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submitPolicy()))
              }, " Confirm payment and commence cover ")
            ])
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.isConfirmationPage)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_12, [
              (_ctx.previousPath)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: _normalizeClass(["btn btn-booster-secondary", { 'd-none': _ctx.isMobileScreen }]),
                    to: _ctx.previousPath,
                    disabled: _ctx.loading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Back ")
                    ]),
                    _: 1
                  }, 8, ["class", "to", "disabled"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                class: "btn btn-booster-primary float-end",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.toNextPage()))
              }, "Next")
            ])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}