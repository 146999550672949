
import { PropType, defineComponent } from "vue";
import SelectInput from "@/components/select-input/SelectInput.vue";
import TextInputSmall from "@/components/text-input/TextInputSmall.vue";
import SingleChoiceQuestionItem from "@/components/single-choice-question-Item/SingleChoiceQuestionItem.vue";
import { DirectDebit } from "@/interfaces/payment/DirectDebit";
import InsuranceClient from "@/rest-client/InsuranceClient";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { ConsentQuestion } from "@/interfaces/ConsentQuestion";

export default defineComponent({
  name: "DirectDebitTemplate",
  components: {
    SelectInput,
    TextInputSmall,
    SingleChoiceQuestionItem
  },
  props: {
    policy: { type: Object as PropType<InsurancePolicy>, required: true },
    directDebit: {type: Object as PropType<DirectDebit | null>, default: null},
    email: { type: String, required: true },
    fullName: { type: String, required: true },
    errors: {type: Object, default: () => {}},
  },
  emits: ["update:directDebit", "update:consentQuestions"],
  data() {
    return {
      consentQuestions: [] as ConsentQuestion[],
      enteredValues: this.directDebit ?? this.getNewDirectDebit(),
      banks: [
        "ASB",
        "ANZ",
        "BNZ",
        "The Co-operative Bank",
        "Heartland",
        "HSBC",
        "Kiwibank",
        "TSB",
        "Westpac",
      ]
    };
  },
  watch: {
    "enteredValues.bankAccountNumber"() {
      let partsLengths = [2, 4, 7, 3];

      // Card number without dash (-)
      let realNumber = this.enteredValues.bankAccountNumber.replace(/-/gi, "");

      let pointer = 0;
      let matches = [];
      let leftOver = "";
      for (const partLength of partsLengths) {
        let part = realNumber.slice(pointer, pointer + partLength);

        if (part.length != partLength) {
          leftOver = part;
          break;
        }

        matches.push(part);
        pointer += partLength;
      }

      // Replace the dashed number with the real one
      if (matches) {
        let formattedValue = matches.join("-");
        if (leftOver && leftOver.length > 0) {
          formattedValue =
            formattedValue +
            (formattedValue && formattedValue.length > 0 ? "-" : "") +
            leftOver;
        }

        this.enteredValues.bankAccountNumber = formattedValue;
      }
    },
    enteredValues: {
      handler() {
        this.$emit("update:directDebit", this.enteredValues);
      },
      deep: true,
    },
    consentQuestions: {
      handler() {
        this.$emit("update:consentQuestions", this.consentQuestions);
      },
      deep: true,
    }
  },
  mounted() {
    this.fetchConsentQuestions();
  },
  expose: ["getNewDirectDebit"],
  methods: {
    async fetchConsentQuestions() {
      const response = await InsuranceClient.getConsentQuestions('InsuranceWebPayment');
      this.consentQuestions = response.body.map((element: any) => {
        return {
          questionId: element.id,
          question: element.question,
          text: element.content,
          options: JSON.parse(element.options),
          value: element.value
        }
      });
    },
    getNewDirectDebit() {
      let directDebit: DirectDebit = {
        fullName: "",
        bankName: "",
        bankAccountNumber: "",
        bankAccountName: "",
        email: "",
        contactNumber: ""
      } as DirectDebit;

      return directDebit;
    },
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }

      return this.errors[field];
    },
  },
});
