
import { defineComponent } from "vue";
import TextInputSmall from '@/components/text-input/TextInputSmall.vue';
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import InsuranceClient from '@/rest-client/InsuranceClient';
import { mapActions, mapState } from 'pinia';
import { insuranceStore } from '@/stores/InsuranceStore';
import { routerStore } from "@/stores/RouterStore";
import { navStore } from "@/stores/NavStore";
import { Address } from "@/interfaces/Address";
import AddressDetails from "@/components/address-details/AddressDetails.vue";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import { InsuredPerson } from "@/interfaces/InsuredPerson";

export default defineComponent({
  name: "ContactDetailsTab",
  components: {
    AddressDetails,
    TextInputSmall,
    LoadingSpinnerWithBackground
  },
  props: {
    screenWidth: {type: String, required: true}
  },
  data() {
    return {
      errorMsg: null as string | null,
      insuredPerson: {} as InsuredPerson | null,
      isPostalAddressSame: true,
      errors: {} as Record<string, any>,
      previousPath: null as string | null,
      nextPath: null as string | null,
      isLoading: true,
    }
  },
  computed: {
    ...mapState(insuranceStore, ['policyInfo']),
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    }
  },
  watch: {
    isPostalAddressSame(newValue) {
      if (!this.isPostalAddressSame && this.insuredPerson
        && this.insuredPerson.addresses
        && !this.insuredPerson.addresses[1]) {
        this.insuredPerson.addresses[1] = this.getNewAddress();
      }
      return newValue;
    }
  },
  created() {
    this.nextPath = this.getPage(1, this.$route.path, this.$route.query);
    this.previousPath = this.getPage(-1, this.$route.path, this.$route.query)

    if (this.policyInfo.insuredPerson) {
      this.insuredPerson = {
        mobile: this.policyInfo.insuredPerson.mobile,
        workPhone: this.policyInfo.insuredPerson.workPhone,
        addresses: this.policyInfo.insuredPerson.addresses,
        medicalPractitioner: this.policyInfo.insuredPerson.medicalPractitioner
      };

      if (this.insuredPerson.addresses
      && this.insuredPerson.addresses.length <= 0) {
        this.insuredPerson.addresses = [
          this.getNewAddress()
        ];
      }

      if (this.insuredPerson.addresses && this.insuredPerson.addresses[1]
        && !this.isSameAddressFromSavedData(this.insuredPerson.addresses)) {
        this.isPostalAddressSame = false;
      }
    } else {
      let insuredPerson: InsuredPerson = {
        workPhone: "",
        mobile: "",
        addresses: [
          this.getNewAddress()
        ]
      };
      this.insuredPerson = insuredPerson;
      this.isPostalAddressSame = true;
    }

    this.isLoading = false;
  },
  methods: {
    ...mapActions(insuranceStore, ["mapFluentValidationErrors"]),
    ...mapActions(routerStore, ["getPage"]),
    ...mapActions(navStore, ['updateNavItemCheckedStatus']),
    isSameAddressFromSavedData(addresses: Array<Address> | null) {
      const isSameAddress = addresses && addresses.length == 1
        && addresses[0].isPostal;

      return isSameAddress;
    },
    getNewAddress() {
      let address: Address = {
        address1: "",
        address2: "",
        address3: "",
        postCode: "",
        city: "",
        country: "",
        isPostal: true,
        isActive: true
      };

      return address;
    },
    UpdateAddresses(insuredPerson: InsuredPerson | null) {
      if (insuredPerson && insuredPerson.addresses) {
        if (this.isPostalAddressSame) {
          insuredPerson.addresses[0].isPostal = true;
          insuredPerson.addresses[0].isActive = true;
          insuredPerson.addresses[0].country = 'New Zealand';
          delete insuredPerson.addresses[0].state;
          if (insuredPerson.addresses[1]) {
            insuredPerson.addresses.splice(1);
          }
        } else {
          insuredPerson.addresses[0].isPostal = false;
          insuredPerson.addresses[1].isPostal = true;
          insuredPerson.addresses[0].isActive = true;
          insuredPerson.addresses[1].isActive = true;
          insuredPerson.addresses[0].country = 'New Zealand';
          insuredPerson.addresses[1].country = 'New Zealand';
          delete insuredPerson.addresses[1].state;
        }
      }

      let patchDocument = [{
        op: "replace", path: "/mobile", value: insuredPerson?.mobile
      },{
        op: "replace", path: "/workPhone", value: insuredPerson?.workPhone,
      },{
        op: "replace", path: "/addresses", value: insuredPerson?.addresses,
      },{
        op: "replace", path: "/medicalPractitioner", value: insuredPerson?.medicalPractitioner,
      }];

      return patchDocument;
    },
    set400Errors(mappedErrors: Record<string, any> | undefined) {
      if (!mappedErrors) {
        return;
      }

      this.errors = mappedErrors;
    },
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }

      return this.errors[field];
    },
    isFieldEmpty(value: string | undefined | null) {
      if (!value || value.length <= 0) {
        return true;
      }

      return false;
    },
    validate() {
      if (!this.insuredPerson) {
          this.errors = {};
          return true;
      }

      // remove all the errors before every validation
      this.errors = {};
      this.errorMsg = null;

      if (this.isFieldEmpty(this.insuredPerson.mobile)
        && this.isFieldEmpty(this.insuredPerson.workPhone)) {
        this.errors['workPhone'] = this.errors['mobile'] =
          "Either mobile or workphone is required";
      }

      // validate address details
      let skipPropertyList = ['mobile', 'workPhone', 'address2', 'address3', 'id', 'isActive', 'state', 'country'];

      let key: keyof InsuredPerson;
      for(key in this.insuredPerson) {
        if (skipPropertyList.includes(key)) {
          continue;
        }

        let value = this.insuredPerson[key];

        if (typeof value == 'object' && value) {
          let addressObj: any = value;
          for (let i = 0; i < 2; i++) {
            if (this.isPostalAddressSame && i == 1) {
              continue;
            }
            let val = addressObj[i];

            for (let addr in val) {
              let address = val[addr];
              if (skipPropertyList.includes(addr)) {
                continue;
              }
              if ((typeof address == 'string' || typeof value == 'number') && (!address || address.length <= 0)) {
                if (!this.errors) {
                  this.errors = {};
                }

                if (!this.errors[`${key}[${i}]`]) {
                  this.errors[`${key}[${i}]`] = {};
                }
                this.errors[`${key}[${i}]`][addr] = 'Answer required';
              }
            }
          }
        }

        if (typeof value == 'string' &&
          (!value || value.length <= 0)) {
          if (!this.errors) {
            this.errors = {};
          }

          this.errors[key] = 'Answer required';
        }

        if (typeof value == 'number' &&
          (!value || value <= 0)) {
          if (!this.errors) {
            this.errors = {};
          }

          this.errors[key] = 'Answer required';
        }
      }

      return this.errors && Object.keys(this.errors).length == 0;
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }
      this.isLoading = true;
      this.UpdateAddresses(this.insuredPerson);
      var updateInsuredPersonRequest =
       {
        gender: this.policyInfo.insuredPerson?.gender,
        smokerStatus: this.policyInfo.insuredPerson?.smokerStatus,
        medicalPractitioner: this.insuredPerson?.medicalPractitioner,
        isUpdatingGenderSmokerStatusOnly: null,
        firstName:this.policyInfo.insuredPerson.firstName,
        middleName: this.policyInfo.insuredPerson.middleName,
        lastName: this.policyInfo.insuredPerson.lastName,
        dateOfBirth: this.policyInfo.insuredPerson.dateOfBirth,
        workPhone: this.insuredPerson?.workPhone,
        email: this.policyInfo.insuredPerson.email,
        mobile: this.insuredPerson?.mobile,
        addresses: this.insuredPerson?.addresses,
        customerId: this.policyInfo.insuredPerson.customerId,
        insuredPersonId: this.policyInfo.insuredPerson.id
      };
      const response = await InsuranceClient.updateInsuredPerson(updateInsuredPersonRequest);

      if (response?.statusCode != 200) {
        this.errorMsg = 'Error happened while submitting data.';
        if (response?.statusCode == 400) {
          this.errors = response?.body.errors;
          const mappedErrors = this.mapFluentValidationErrors(response.body?.errors);
          this.set400Errors(mappedErrors);
          this.errorMsg += ' Please check submitted data.'
        }

        this.isLoading = false;
        return;
      }

      if (this.nextPath) {
        this.updateNavItemCheckedStatus('Contact details');
        this.$router.push(this.nextPath);
      }
    },
  }
})
