
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import CliConfirmationTab from "@/components/tabs/confirmation-tab/CliConfirmationTab.vue";
import SsliConfirmationTab from "@/components/tabs/confirmation-tab/SsliConfirmationTab.vue";
import ProductQuoteDataGenerator from "@/data-generator/ProductQuoteDataGenerator";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";

export default defineComponent({
  name: "ConfirmationTab",
  components: {
    CliConfirmationTab,
    SsliConfirmationTab,
    LoadingSpinnerWithBackground
  },
  props: {
    screenWidth: {type: String, required: true}
  },
  data() {
    return {
      isPolicyReferred: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    isDDNotBankAccountHolder() {
      return this.policyInfo.policy.paymentConfigurations[0].method == 'Direct debit' &&
      this.policyInfo.policy.paymentConfigurations[0].directDebit?.email != null &&
      this.policyInfo.insuredPerson.email != this.policyInfo.policy.paymentConfigurations[0].directDebit?.email
    }
  },
  async mounted() {
    this.isLoading = true;
    let dataGenerator = new ProductQuoteDataGenerator();
    let policyData = await dataGenerator.getPolicySchedule();
    this.isPolicyReferred = this.checkPolicyReferred(policyData);
    this.isLoading = false;
  },
  methods: {
    checkPolicyReferred(policyData: any) {
  const isPolicyApproved = ProductQuoteDataGenerator.approvedStatusList.includes(policyData.policyStatusName);

  // If policy is approved, return false immediately as no further check is needed
  if (isPolicyApproved) {
    return false;
  }

  // Determine if the policy needs referral based on status and type
  let isReferred = false;
  
  if (this.policyInfo.policy.policyType === "1") {
    isReferred = ProductQuoteDataGenerator.referredStatusListSSLI.includes(policyData.policyStatusName);
  } else {
    isReferred = ProductQuoteDataGenerator.referredStatusListCLI.includes(policyData.policyStatusName);
  }

  // Additional check for referral if not already referred by status
  if (!isReferred) {
    isReferred = this.policyInfo.policy.policyBenefits.some(
      policyBenefit => policyBenefit.quircPolicy?.note?.length > 0
    );
  }

  return isReferred;
    },
    openAdviserHub() {
      if (this.policyInfo.policy.policyType == "1") {
        let url = process.env.VUE_APP_MEM_POR_URL;
        url = url.endsWith('/') ? url : url + '/';
        window.location.href = url + "SessionRefresh";
      } else {
        window.location.href = process.env.VUE_APP_ADV_HUB_URL;
      }
    }
  }
});
